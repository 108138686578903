import React from 'react'
import {graphql} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()
  const { t } = useTranslation()

  return (
    <Layout {...props}>
      <Seo title={t('About').concat(' SOP IT Solutions - ').concat(t('Expanding Horizons with Technology.')) } />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About SOP IT Solutions'
          subheader='We are at the forefront of technology development. With an array of top-notch experts and a wide range of competencies, we develop cutting-edge technology solutions. Founded with the goal of implementing IT projects quickly and successfully as a full service with the help of international resources. There is talent everywhere - our goal is to make this worldwide pool of talent available to our clients. Project development, design, management, and quality assurance is provided by us and our expert team based in Germany. Our clients also have a dedicated contact person in Germany for every project, who provides updates and recieves feedback throughout the project development in an agile process.'
        />
      </Stack>
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='The Lead Team'
          subheader=''
        />
      </Stack>

      <Stack>
        <Main>
          {authors.map((author, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`
